import React from "react";
import Page from "../../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Hundflytvast({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Zcooly recension (2022) - Bästa mattespelet för barn"

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Hur mycket kostar mattespelet Zcooly?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Det finns två olika prenumerationer att välja mellan i Zcooly, Zcooly Lilla som kostar 119 kr/mån och Zcooly Stora som kostar 189 kr/mån."
        }
      },
      {
        "@type": "Question",
        "name": "Hur många barn kan dela på ett konto?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "För att kunna dela på ett konto behöver man välja Zcooly Stora, och då kan man ha upp till 3 barn på ett och samma konto."
        }
      }
    ]
  }

  return (
    <>
      <Page title={title} description="Zcooly är ett bra spel för att lära sig matematik! Det är ett mycket visuellt och interaktivt sätt att lära sig." schema={schema}>
        <div className="w-full py-3 bg-gray-100"><p className="flex items-center max-w-screen-xl mx-auto px-4 justify-between w-full">Inlägget innehåller reklam genom annonslänkar.</p></div>

        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="zcooly hemsida" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>Zcooly är ett bra spel för barn som vill lära sig matte. Det är interaktivt och roligt, och håller barnen underhållna med många olika nivåer. Gränssnittet är användarvänligt och inbjudande, med ljusa färger och roliga illustrationer.</p>
            <p>Vi har själva testat mattespelet Zcooly och tycker det är ett väldigt bra och roligt spel för barn att lära sig matte på. Det är visuellt och interaktivt, och lätt att följa med i spelet.</p>
            <p>I denna artikeln kommer vi ta en närmare titt mattespelet Zcooly, hur det fungerar och varför vi tror att det är ett bra spel för barn som vill lära sig matte.</p>
            <h2 id="-r-zcooly-ett-bra-mattespel-">Är Zcooly ett bra mattespel?</h2>
            <p>Zcooly är ett bra mattespel för barn eftersom det är interaktivt och roligt. Det håller barnen underhållna med många olika nivåer, och gränssnittet är användarvänligt och inbjudande. Spelet är lätt att följa med i, och passar barn mellan 5-12 år.</p>
            <p>I appen så finns det massor av olika minispel där man får lära sig mer om klockan, addition, subtraktion, multiplikation, bråk och geografi. Nivån på de olika övningarna går från lätt till svårt, så att det ska passa alla barn.</p>
            <p>Spelmiljön är också mycket lättnavigerad och användarvänlig, så barnen lär sig snabbt hur man spelar spelet. Det märks även att spelets skapare har lagt ner mycket arbete på att skapa en stor och detaljerad värld, som barnen kan upptäcka och utforska.</p>
            <p>Det vi gillar mest med mattespel Zcooly är att det är ett bra sätt för barn att lära sig matematik, samtidigt som det gör skärmtiden mer meningsfull.</p>
            <p>Zcooly har även fått väldigt bra recensioner på App Store och Google Play, och är väldigt populärt bland barnen.</p>
            <div className="w-full flex justify-center">
              <a href="https://id.zcooly.se/t/t?a=1707311680&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                <button className="mx-auto mb-5 inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Zcooly gratis här!</button>
              </a>
            </div>

            <iframe width="100%" height="420" src="https://www.youtube.com/embed/1G-JMUbv8Os" className="rounded-lg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            <h2 id="hur-fungerar-mattespelet-zcooly-">Hur fungerar mattespelet Zcooly?</h2>
            <p>För att komma igång med Zcooly behöver du skapa ett konto vilket du gör enklast genom deras hemsida. När du har gjort detta så kan du sedan börja spela direkt i webbläsaren eller ladda ner appen för att spela på din mobil eller surfplatta.</p>
            <p>När man startar spelet för första gången så behöver man skapa ett avatar genom att exempelvis välja hår- och ögonfärg m.m. Man får även välja kläder och accessoarer till sin avatar. När det är klart kan man börja spela spelet och då börjar äventyret!</p>
            <p>En stor fördel för dig som förälder är också att man får tydliga instruktioner och beskrivningar på vad barnet lär sig under varje vecka i spelet.</p>
            <p>På så vis kan du följa med i barnets utveckling och du får också pedagogiska tips på hur du kan lära barnet ytterligare för att de ska få ut maximalt av spelet.</p>
            <GatsbyImage image={data.app.childImageSharp.gatsbyImageData} className="rounded-xl" alt="zcooly app" />
            <h2 id="-r-zcooly-gratis-att-anv-nda-">Är Zcooly gratis att använda?</h2>
            <p>Det är gratis att prova på Zcooly under deras provperiod som varar i 30 dagar. Därefter kostar det 99 kronor per månad.</p>
            <p>Provperioden gör det möjligt för barnet och föräldrar att testa spelet för att se om det passar för dem, innan de bestämmer sig för att betala för det.</p>
            <p>Om man inte tycker om Zcooly eller inte vill fortsätta använda mattespelet så avslutar man enkelt sin prenumeration genom att logga in via hemsidan.</p>
            <p>Vi tycker att 99 kr per månad är rimligt pris och det är helt klart värt pengarna för den tid och de matematiska kunskaper barnet får med sig från spelet.</p>
            <div className="w-full flex justify-center">
              <a href="https://id.zcooly.se/t/t?a=1707311680&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Zcooly gratis här!</button>
              </a>
            </div>
            <h2 id="vad-ing-r-i-en-zcooly-prenumeration-">Vad ingår i en Zcooly-prenumeration?</h2>
            <p>En Zcooly-prenumeration ger dig tillgång till allt spelinnehåll, inklusive nya nivåer som lanseras eftersom, och massor av minispel. Totalt sätt finns det över 300 olika minispel i Zcooly.</p>
            <p>Några av de sakerna som barnet lär sig i mattespelet Zcooly är:</p>
            <ul>
              <li><strong>Klockan.</strong></li>
              <li><strong>Addition.</strong></li>
              <li><strong>Subtraktion.</strong></li>
              <li><strong>Multiplikation.</strong></li>
              <li><strong>Bråk.</strong></li>
              <li><strong>Geografi.</strong></li>
            </ul>
            <p>Dessa sakerna får barnet  lära sig på ett lekfullt och roligt sätt genom att spela de olika minispelen i Zcooly.</p>
            <p>Två exempel på minispel är Kakmakeriet där barnet får lära sig om bråk, och Affären, vilket är en plats där barnet får öva på addition och subtraktion.</p>
            <p>Som förälder får du även mail i form av pedagogiska tips varje vecka och uppdateringar på vad dina barn har lärt sig. Detta tycker vi är väldigt bra, eftersom det hjälper dig att följa barnets framsteg och ge dem stöd i deras lärande.</p>
            <GatsbyImage image={data.kak.childImageSharp.gatsbyImageData} className="rounded-xl" alt="kakmakeriet zcooly" />

            <h2 id="f-r-vilka-barn-passar-zcooly-">För vilka barn passar Zcooly?</h2>
            <p>Zcooly skriver själva på deras hemsida att det är ett mattespel för barn mellan 5-12 år. Efter att ha testat spelet så håller vi med om den åldersgruppen. Mattespelet passar främst barn som befinner sig i grundskolan.</p>
            <p>Vi anser däremot att det inte finns lika många övningar för äldre barn som är 11-12 år eftersom mycket av fokuset är på de yngre barnen, men de går självklart att också hitta roliga minispel för äldre barn att leka med.</p>
            <p>För att få genomföra vissa övningar så behöver man ha angett en viss ålder i appen. Men det går att göra dessa övningar också om barnet vill utmana sig själv lite extra genom att ändra åldern på barnet i appen.</p>
            <h2 id="vad-s-ger-andra-om-zcooly-">Vad säger andra om Zcooly?</h2>
            <p>Zcooly har många bra recensioner och det finns många som gillar spelet. De allra flesta tycker att det är en <a href="https://www.pluggtips.se/blogg/spelifiering-i-larandet/">bra matte app för barn</a> med många engagerade minispel och vacker spelgrafik.</p>
            <p>Några recensioner säger följande:</p>
            <ul>
              <li>&quot;Våra barn älskar att sitta med matten nu, t.om. dem som tyckt att det varit tråkigt och jobbigt med matten innan.&quot;</li>
              <li>&quot;Ellah behöver öva på multiplikationstabellen inför Nationella prov i vår &amp; hon har nu hittat ett roligt sätt att lära sig, hon som annars tycker att matematik är ganska tufft.&quot;</li>
              <li>&quot;Min 5-åring håller på med multiplikation och division tack vare Zcooly!&quot;</li>
            </ul>
            <p>Dessa recensioner kommer från Zcoolys hemsida.</p>
            <GatsbyImage image={data.main.childImageSharp.gatsbyImageData} className="rounded-xl" alt="medlemskap" />
            <h2 id="hur-ser-vi-p-mattespel-zcooly-">Hur ser vi på mattespel Zcooly?</h2>
            <p>Vi recenserar och skriver vår egen personliga åsikt om produkter, tjänster och spel för barn. Med det sagt gillar vi verkligen Zcooly, och vi tycker det är ett mycket bra spel för att lära barn matematik.</p>
            <p>Det finns många olika minispel i spelet, och alla är väldigt engagerande samtidigt som de är lärorika. Vi gillar också att föräldrar får tips via mail varje vecka, så att de kan följa med i barnens framsteg.</p>
            <p>På Zcoolys hemsida säger de att Zcooly passar barn mellan 5-12 år, och det håller vi med om, men vi vill påpeka att antalet övningar för de äldre barnet är något lägre jämfört med de yngre barnen.</p>
            <p>Det pris som Zcooly tar, 99 kronor per månad, tycker vi är rimligt med tanke på alla olika den tid och de matematiska kunskaper barnet får med sig från spelet.</p>
            <h2 id="skillnaden-p-zcooly-och-albert">Skillnaden på Zcooly och Albert</h2>
            <p>En annan populärt matteapp för barn som vanligtvis jämförs med Zcooly är Albert.</p>
            <p>Skillnaden mellan dessa två apparna är främst att Zcooly mer kan liknas som ett spel, medan Albert är mer än lärplattform. Albert har mer en &quot;skolliknande&quot; upplevelse och innehåller också flera olika ämnen såsom programmering, svenska och engelska.</p>
            <p>Zcooly är också billigare än Albert. Priset för en månad för Zcooly är 99 kronor, medan priset för Albert är 119 kronor per månad.</p>
            <p>Vi upplever också att Zcooly är roligare för barnet eftersom det är mer inriktat på att vara ett spel.  Albert kan däremot vara ett bra val om ditt barn är intresserad av att lära sig flera olika ämnen, och inte bara matematik.</p>
            <p>Om du vill läsa mer om Albert, så kan du läsa vår <a href="/recensioner/hejalbert/">recension av matteappen Albert</a>.</p>
            <div className="">
              <a href="https://id.zcooly.se/t/t?a=1707311680&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Zcooly gratis här!</button>
              </a>
            </div>
            <br/>
            <br/>
          </article>
        </div>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "zcooly-website.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    app: file(relativePath: { eq: "zcooly-app.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    main: file(relativePath: { eq: "zcooly-appstore.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    kak: file(relativePath: { eq: "kakmakeriet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
   
  }
`;
