import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import SEO from "../SEO";

export default function Page({title,description,children,image=null,schema=false}) {
  return (
    <>
      <SEO
        title={title}
        description={description}
        image={image}
        schema={schema}
      />
      <Navbar />
      {children}
      <Footer dark={true} />
    </>
  );
}